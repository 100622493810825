import React from 'react';
import { useContext } from 'react';
import { TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import * as commonFnc from '../common/commonFnc';
import { GlobalContext } from '../globalState'


const KintaiHeader = (props) =>{

    const {shiftStartDay, setShiftStartDay, Kintai_bscs, Login_user_info,display_user_info, Users} = props;
    const shift_type_cd_hash = useContext(GlobalContext).shift_type_cd;

    var tmpDay = new Date(shiftStartDay);
    let shiftStartDayToTime = tmpDay.getTime();
    let shiftEndDay = tmpDay.setMonth(tmpDay.getMonth() + 1);

    const handleButtonClick = (p) =>{
        var d = new Date(shiftStartDay);
        setShiftStartDay(d.setMonth(d.getMonth() + p))

        shiftEndDay = d.setMonth(d.getMonth() + 1);
        
    };
    var disp_Kintai_bscs = Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && item.date < shiftEndDay);
    const publicHolidayDays = Kintai_bscs.filter(
            item => new Date(item.date).getTime() >= shiftStartDayToTime 
            && new Date(item.date).getTime() < shiftEndDay 
            && item.public_holiday_time > 0)
            .reduce((sum,element)=>{return sum + element.public_holiday_time},0)/8;
    const paidHolidayDays = Kintai_bscs.filter(
            item => new Date(item.date).getTime() >= shiftStartDayToTime 
            && new Date(item.date).getTime() < shiftEndDay 
            && item.paid_holiday_time > 0)
            .reduce((sum,element)=>{return sum + element.paid_holiday_time},0)/8;

    const dispPublicHolidayDays = publicHolidayDays + "日";
    const dispPaidHolidayDays = Math.trunc(paidHolidayDays) + "日" + ((paidHolidayDays%1===0)?"":(paidHolidayDays-Math.trunc(paidHolidayDays))*8+"h");

    const dispRemainingPublicHolidayDays = Math.trunc(display_user_info?.enable_public_holiday_days) + "日"
            + ((display_user_info?.enable_public_holiday_days%1===0)?""
            :"/" + (display_user_info?.enable_public_holiday_days - Math.trunc(display_user_info?.enable_public_holiday_days))*8 + "h");
    const dispRemainingPaidHolidayDays = Math.trunc(display_user_info?.enable_paid_holiday_days) + "日" 
            + (display_user_info?.enable_paid_holiday_days%1===0?""
            :"/" + (display_user_info?.enable_paid_holiday_days - Math.trunc(display_user_info?.enable_paid_holiday_days))*8 + "h");

    return (
        <TableContainer component={Paper}>
            {!disp_Kintai_bscs ? <div>Loding...</div> : 
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className="kintaiList_userName">{display_user_info?.user_id}　{display_user_info?.first_name}　{display_user_info?.second_name}</TableCell>
                        <TableCell align="right">勤務</TableCell>
                        <TableCell align="right">休憩</TableCell>
                        <TableCell align="center">時間外</TableCell>
                        <TableCell align="center">深夜</TableCell>
                        <TableCell align="center">遅刻</TableCell>
                        <TableCell align="center">早退</TableCell>
                        <TableCell align="center">外出</TableCell>
                        <TableCell align="center">業務外</TableCell>
                        <TableCell align="center">公休</TableCell>
                        <TableCell align="center">有給</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <div>
                                <Button onClick={()=>handleButtonClick(-1)}>◁</Button>
                                <TextField value={commonFnc.formatDate(shiftStartDay)} inputProps={{style: { textAlign: 'center' }}} className="request_kintai_header_date"/>
                                <Button onClick={()=>handleButtonClick(1)}>▷</Button>
                            </div>
                        </TableCell>
                        <TableCell align="right">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.work_time?item.work_time:0), 0))}</TableCell>
                        <TableCell align="right">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.rest_time?item.rest_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.over_time?item.over_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.midnight_over_time?item.midnight_over_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.behind_time?item.behind_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.early_time?item.early_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.out_time?item.out_time:0), 0))}</TableCell>
                        <TableCell align="center">{commonFnc.getHHmm(Kintai_bscs.filter(item => new Date(item.date).getTime() >= shiftStartDayToTime && new Date(item.date).getTime() < shiftEndDay).reduce((sum,item) => sum + (item.training_time?item.training_time:0), 0))}</TableCell>
                        <TableCell align="center" className="diagonal"><div class="col-header">{dispPublicHolidayDays}</div><div class={`row-header ${display_user_info?.enable_public_holiday_days<0?"nagativeValue":""}`}>{dispRemainingPublicHolidayDays}</div></TableCell>
                        <TableCell align="center" className="diagonal"><div class="col-header">{dispPaidHolidayDays}</div><div class={`row-header ${display_user_info?.enable_paid_holiday_days<0?"nagativeValue":""}`}>{dispRemainingPaidHolidayDays}</div></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            }
        </TableContainer>
    )
}
export default KintaiHeader;