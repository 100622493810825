import * as commonFnc from '../common/commonFnc';


/**
 * 開始時刻が終了時刻を超えているか
 * @param {Number} from_time 
 * @param {Number} to_time 
 * @returns 
 */
export function isFromtimeOverTotime(from_time, to_time){

    return from_time > to_time;

}

/**
 * 勤務時間数を算出する。
 * @param {Number} total_time 勤怠申請画面で入力されている(終了時刻 - 開始時刻)時間数
 * @param {Number} rest_time 休憩時間数
 * @param {Object} Shift_mst シフトマスタ
 * @param {Object} shift_group_cd_hash シフトグループハッシュ
 * @returns 
 */
export function calcWorkTimes(total_time, rest_time, Shift_mst, shift_group_cd_hash, time_paid_holiday_time_msec) {

    if(!Shift_mst || !Shift_mst.shift_time_from || !Shift_mst.shift_time_to){
        // シフトマスタの開始終了時刻が設定されていない場合
        if(total_time < 3600000*8){
            return total_time - rest_time;
        }else{
            return 3600000*8;
        }
    }else{

        // シフトマスタの開始終了時刻が設定されていない場合
        var shiftWorkMsecFrom = Shift_mst ? commonFnc.HHMMtoMsec(Shift_mst.shift_time_from) : 0;
        var shiftWorkMsecTo = Shift_mst ? commonFnc.HHMMtoMsec(Shift_mst.shift_time_to) : 0;

        // 1:当日　2:翌日
        if(Shift_mst.shift_time_to_date_flg==='2'){
            shiftWorkMsecTo += 24 * 60 * 60 * 1000;
        }
        var shift_work_time = shiftWorkMsecTo - shiftWorkMsecFrom;



        if(Shift_mst.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"]){
            // 宿直なら勤務時間0
            return 0;
        }else if(Shift_mst.shift_group_cd === shift_group_cd_hash["OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
            // 宿日直なら勤務時間8
            return 3600000*8;
        }else if(Shift_mst.shift_group_cd === shift_group_cd_hash["HARF_OVER_NIGHT_WATCH_SHIFT_GROUP_CD"]){
            // 半日宿日直なら勤務時間4
            return 3600000*4;
        }else if(time_paid_holiday_time_msec>0){
            // 時間有給を使用している場合
            if(total_time+time_paid_holiday_time_msec < shift_work_time){
                // 時間有給を使用して遅刻早退している場合
                return total_time - rest_time;
            }else{
                // 時間有給を使用して遅刻早退していない場合
                return shift_work_time - time_paid_holiday_time_msec - rest_time;
            }
        }else if(total_time < shift_work_time){
            // 遅刻早退している場合
            return total_time - rest_time;
        }else{
            // 遅刻早退していない場合
            return shift_work_time - Shift_mst.rest_time;
        }

    }
}

/**
 * 休憩時間数を算出する。
 * @param {Number} totalTimes 
 * @param {Object} Shift_mst 
 * @returns 
 */
export function calcRestTimes(totalTimes, Shift_mst){
    if(Shift_mst){
        if(totalTimes < Shift_mst.rest_time){
            return 0;
        }else{
            return Shift_mst.rest_time;
        }
    }else{
        if(totalTimes < 3600000*6 ){
            return 0;
        }else if(totalTimes < 3600000*8 ){
            return 3600000*0.75;
        }else{
            return 3600000;
        }
    }
}
    
/**
 * 遅刻時間数を算出する。
 * @param {Number} workMsecFrom 
 * @param {Date} kintaiDate 
 * @param {Object} Shift_mst 
 * @returns 
 */
export function calcBehindHours(workMsecFrom, kintaiDate, Shift_mst, time_paid_holiday_time_msec){

    if(!Shift_mst) return 0;
    var shiftWorkMsecFrom = new Date(commonFnc.formatDate(kintaiDate) + " " + Shift_mst.shift_time_from).getTime();
    var behind_time = workMsecFrom - (shiftWorkMsecFrom + time_paid_holiday_time_msec);

    return behind_time<0 ? 0 : behind_time;

}

/**
 * 早退時間数を算出する。
 * @param {Number} workMsecTo 
 * @param {Date} kintaiDate 
 * @param {Object} Shift_mst 
 * @returns 
 */
export function calcEarlyHours(workMsecTo, kintaiDate, Shift_mst, time_paid_holiday_time_msec){

    if(!Shift_mst) return 0;

    const tmpKintaiDate = new Date(kintaiDate);
    
    // 1:当日　2:翌日
    if(Shift_mst.shift_time_to_date_flg==='2'){
        tmpKintaiDate.setDate(new Date(tmpKintaiDate).getDate()+1);
    }

    var shiftWorkMsecTo = new Date(commonFnc.formatDate(tmpKintaiDate) + " " + Shift_mst.shift_time_to).getTime();
    
    // var early_time = shiftWorkMsecTo - workMsecTo;
    // 時間有給を取得した場合、シフト退勤時間を短縮する仕様のため早退時間の計算の際には時間有給を考慮
    var early_time = (shiftWorkMsecTo - time_paid_holiday_time_msec) - workMsecTo;

    return early_time<0 ? 0 : early_time;

}



/**
 * 残業時間数を算出する。
 * @abstract 宿直シフトの場合は働いた時間は全て残業時間として計算するため深夜残業時間0とする。
 * @param {*} Kintai_bsc 
 * @param {*} over_time_from 時間外開始時刻
 * @param {*} over_time_to 時間外終了時刻
 * @param {*} kintaiDate 対象日付
 * @param {*} shift_group_cd_hash シフトグループハッシュ
 * @returns 
 */
export function calcOverHours(Kintai_bsc, over_time_from, over_time_to, kintaiDate, shift_group_cd_hash){
    // 残業時間数の算出
    var overTimeMsecFrom = new Date(over_time_from).getTime();
    var overTimeMsecTo = new Date(over_time_to).getTime();
    var time2200 = new Date(commonFnc.formatDate(kintaiDate) + " 22:00");
    var time0500 = new Date(commonFnc.formatDate(kintaiDate) + " 5:00");
    time0500.setDate(time0500.getDate() + 1);

    var tmp_mnOverMsecFrom = overTimeMsecFrom<time2200.getTime() ? time2200.getTime() : overTimeMsecFrom;
    var tmp_mnOverMsecTo = overTimeMsecTo>time0500.getTime() ? time0500.getTime() : overTimeMsecTo;
    var midnight_over_time = (overTimeMsecTo<=time2200.getTime() || overTimeMsecFrom>=time0500.getTime()) ? 0 : 7*60*60*1000 - (tmp_mnOverMsecFrom-time2200.getTime()) - (time0500.getTime()-tmp_mnOverMsecTo);
    var over_time = overTimeMsecTo - overTimeMsecFrom - midnight_over_time;

    if(Kintai_bsc.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"]){
        // 宿直なら深夜残業は0
        return [overTimeMsecTo - overTimeMsecFrom, 0]
    }else{
        return [over_time, midnight_over_time]
    }

}

/**
 * 深夜勤務時間を算出する。
 * @param {*} Kintai_bsc 
 * @param {*} shift_group_cd_hash シフトグループハッシュ
 * @returns 
 */
export function calcMidnightHours(workMsecFrom, workMsecTo, Kintai_bsc, shift_group_cd_hash){
    // 残業時間数の算出
    var workTimeMsecFrom = new Date(Kintai_bsc.work_time_from).getTime();
    var workTimeMsecTo = new Date(Kintai_bsc.work_time_to).getTime();
    var time2200 = new Date(commonFnc.formatDate(Kintai_bsc.date) + " 22:00");
    var time0500 = new Date(commonFnc.formatDate(Kintai_bsc.date) + " 5:00");
    time0500.setDate(time0500.getDate() + 1);

    var tmp_mnWorkMsecFrom = workMsecFrom<time2200.getTime() ? time2200.getTime() : workMsecFrom;
    var tmp_mnWorkMsecTo = workMsecTo>time0500.getTime() ? time0500.getTime() : workMsecTo;
    var midnight_work_time = (workMsecTo<=time2200.getTime() || workMsecFrom>=time0500.getTime()) ? 0 : (tmp_mnWorkMsecTo - tmp_mnWorkMsecFrom) - Kintai_bsc.midnight_over_time;
    // var work_time = Kintai_bsc.work_time - midnight_work_time;

    if(Kintai_bsc.shift_group_cd === shift_group_cd_hash["NIGHT_WATCH_SHIFT_GROUP_CD"] || Kintai_bsc.shift_group_cd === shift_group_cd_hash["NIGHT_SHIFT_GROUP_CD"]){
        // 宿直,夜勤なら深夜勤務時間は0
        return 0
    }else{
        return midnight_work_time
    }

}



/**
 * 公休有給加算時間数を取得
 * 変更前後のシフトの有給公休消費時間の差。
 * @param {*} Shift_msts シフトマスタ
 * @param {*} beforeShiftTypeCd 変更前シフト種別CD
 * @param {*} afterShiftTypeCd 変更後シフト種別CD
 * @param {*} name 要素名
 * @returns 消費日数
 */
export function getReduceHolidayTime(Shift_msts, beforeShiftTypeCd, afterShiftTypeCd, name){

    const before_shift = Shift_msts.find((p)=>p.shift_type_cd === beforeShiftTypeCd);
    const after_shift = Shift_msts.find((p)=>p.shift_type_cd === afterShiftTypeCd);

    const afterTime = after_shift&&after_shift[name]?after_shift[name]:0;
    const beforeTime = before_shift&&before_shift[name]?before_shift[name]:0;

    let time = parseFloat(afterTime - beforeTime);

    return time;
}

/**
 * 勤務時間変更処理
 * @abstract 入力された時間から勤務時間を算出する。※宿直シフトの場合は働いた時間は全て残業時間として計算するため勤務時間0とする。
 * @param {*} name 
 * @param {*} value 
 * @param {*} Kintai_bsc 
 * @param {*} Shift_msts 
 * @param {*} shift_group_cd_hash シフトグループハッシュ
 * @param {*} paidHolidayTime 有給申請時間
 * @param {*} unitWorkTimeType 勤務時間の丸目設定
 * @returns 
 */

export function changeWorkTime(name, value, Kintai_bsc, Shift_msts, shift_group_cd_hash, paidHolidayTime, unitWorkTimeType){

    var result = {};
    var workMsecFrom =  0;
    var workMsecTo =  0;
    var isNotExistWorkTimeFromOrTo = false;

    if(!Kintai_bsc || !Kintai_bsc.shift_type_cd){
        isNotExistWorkTimeFromOrTo = true;
    }else if(name === "work_time_from"){
        if(Kintai_bsc.work_time_to === "" || Kintai_bsc.work_time_to === undefined || Kintai_bsc.work_time_to === null) isNotExistWorkTimeFromOrTo = true;
    }else{
        if(Kintai_bsc.work_time_from === "" || Kintai_bsc.work_time_from === undefined || Kintai_bsc.work_time_from === null) isNotExistWorkTimeFromOrTo = true;
    }

    // どちらかが設定されていない場合は終了
    if(isNotExistWorkTimeFromOrTo){
        result = {
            ...Kintai_bsc,
            [name]: value
        }
        return result;
    }

    if(name === "work_time_from"){
        workMsecFrom = new Date(value).getTime();
        workMsecTo =  new Date(Kintai_bsc.work_time_to).getTime();
    }else{
        workMsecFrom = new Date(Kintai_bsc.work_time_from).getTime();
        workMsecTo = new Date(value).getTime();
    }
    
    const Shift_mst = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
    const tmpKintaiDate = new Date(Kintai_bsc.date);

    // 時間有給時間　通常有給/半日有給はカウントしない
    const timePaidHolidayShift = Kintai_bsc.paid_holiday_shift_type_cd?Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.paid_holiday_shift_type_cd):null;
    const time_paid_holiday_time_msec = timePaidHolidayShift?(timePaidHolidayShift.paid_holiday_time?timePaidHolidayShift.paid_holiday_time:0) * 60 * 60 * 1000:0;
    const time_paid_b_a_cd = timePaidHolidayShift?timePaidHolidayShift.b_a_cd:"0";
    
    // 1:当日　2:翌日
    if(Shift_mst.shift_time_to_date_flg==='2'){
        tmpKintaiDate.setDate(new Date(tmpKintaiDate).getDate()+1);
    }

    const shiftMsecFrom = new Date(commonFnc.formatDate(Kintai_bsc.date) + " " + Shift_mst.shift_time_from).getTime();
    const shiftMsecTo = new Date(commonFnc.formatDate(tmpKintaiDate) + " " + Shift_mst.shift_time_to).getTime();
    
    let workMsecFromForCalc = workMsecFrom;
    let workMsecToForCalc = workMsecTo;
    

    // if(isFromtimeOverTotime(workMsecFrom, workMsecTo)){
    //     alert("終了時刻は開始時刻より後ろの時刻を設定してください。")
    //     return;
    // }
    

    if(unitWorkTimeType==='1'){
        // シフト時間に丸める

        if(time_paid_b_a_cd === "1"){
            // 前有給
            // シフト時間に丸める シフト終了時間を時間有給分丸める
            const tmpShiftMsecFrom = shiftMsecFrom + time_paid_holiday_time_msec;
    
            workMsecFromForCalc = workMsecFrom<tmpShiftMsecFrom?tmpShiftMsecFrom:workMsecFrom;
            workMsecToForCalc = workMsecTo>shiftMsecTo?shiftMsecTo:workMsecTo;

        }else if(time_paid_b_a_cd === "2"){
            // 後有給
            // シフト時間に丸める シフト終了時間を時間有給分丸める
            const tmpShiftMsecTo = shiftMsecTo - time_paid_holiday_time_msec;
    
            workMsecFromForCalc = workMsecFrom<shiftMsecFrom?shiftMsecFrom:workMsecFrom;
            workMsecToForCalc = workMsecTo>tmpShiftMsecTo?tmpShiftMsecTo:workMsecTo;
        }else{
            workMsecFromForCalc = workMsecFrom<shiftMsecFrom?shiftMsecFrom:workMsecFrom;
            workMsecToForCalc = workMsecTo>shiftMsecTo?shiftMsecTo:workMsecTo;
        }
    }

    var total_time = 0;
    var rest_time = 0;
    var work_time = 0;
    var over_time = 0;
    var midnight_work_time = 0;
    var midnight_over_time = 0;
    var behind_time = 0;
    var early_time = 0;
    var training_time = 0;
    
    if(Shift_mst?.shift_group_cd === shift_group_cd_hash["HOLIDAY_SHIFT_GROUP_CD"] 
        || Shift_mst?.shift_group_cd === shift_group_cd_hash["SICK_LEAVE_GROUP_CD"]) {
        // 休日は全て業務外時間
        training_time = workMsecTo - workMsecFrom;
    }else if(Shift_mst?.shift_time_from === "00:00" && Shift_mst?.shift_time_to === "00:00" ){
        // その他シフト(fromとtoがどちらも0:00)は全て勤務時間
        rest_time = new Date(Kintai_bsc.rest_time).getTime();
        work_time = workMsecTo - workMsecFrom - rest_time;
    }else{

        total_time = workMsecToForCalc - workMsecFromForCalc;
        rest_time = calcRestTimes(total_time, Shift_mst);
        work_time = calcWorkTimes(total_time, rest_time, Shift_mst, shift_group_cd_hash, time_paid_holiday_time_msec);
        over_time = Kintai_bsc.over_time ? Kintai_bsc.over_time : 0;
        midnight_over_time = Kintai_bsc.midnight_over_time ? Kintai_bsc.midnight_over_time : 0;
        midnight_work_time = calcMidnightHours(workMsecFrom, workMsecTo, Kintai_bsc, shift_group_cd_hash);
        
        if(time_paid_b_a_cd === "1"){
            // 前有給
            early_time = calcEarlyHours(workMsecTo, Kintai_bsc.date, Shift_mst, 0);
            behind_time = calcBehindHours(workMsecFrom, Kintai_bsc.date, Shift_mst, time_paid_holiday_time_msec);
        }else{
            // 後有給
            early_time = calcEarlyHours(workMsecTo, Kintai_bsc.date, Shift_mst, time_paid_holiday_time_msec);
            behind_time = calcBehindHours(workMsecFrom, Kintai_bsc.date, Shift_mst, 0);
        }
        if(unitWorkTimeType==='1'){
            const clockinTotalTime = workMsecTo - workMsecFrom;

            // 遅刻早退時間は加味せず計算　1時間のこり15分遅刻で自己研鑽1時間
            if(clockinTotalTime- time_paid_holiday_time_msec - total_time > 0){
                training_time = clockinTotalTime - total_time;
            }else{
                training_time = Math.max(clockinTotalTime - rest_time - work_time - over_time - midnight_over_time, 0);
            }
        }else{
            training_time = Math.max(total_time - rest_time - work_time - over_time - midnight_over_time, 0);
        }

    }

    result = {
        ...Kintai_bsc,
        [name]: value,
        work_time: work_time,
        midnight_work_time,
        rest_time: rest_time,
        training_time: training_time,
        behind_time: behind_time,
        early_time: early_time
    }

    return result
}

/**
 * 申請時間の合計がトータル時間と一致するか確認する。
 * @param  {Kintai_bsc} Kintai_bsc 勤怠情報
 * @param  {Array} Shift_msts シフトマスタ
 * @param {*} unitWorkTimeType 勤務時間の丸目設定
 * @returns {boolean} 
 */
export function isNotEqualTotalTimeAndRequestTime(Kintai_bsc, Shift_msts, unitWorkTimeType){

    if(Shift_msts?.length===0 || !Kintai_bsc.work_time_from || !Kintai_bsc.work_time_to || !Kintai_bsc.shift_type_cd) return false;
    
    // 有給公休　fromとtoがどちらも0:00
    if(commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_from) === "00:00" && commonFnc.ConvertJSTHHMM(Kintai_bsc.work_time_to) === "00:00" ) return false;
    
    const workMsecFrom = new Date(Kintai_bsc.work_time_from).getTime();
    const workMsecTo =  new Date(Kintai_bsc.work_time_to).getTime();

    const Shift_mst = Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.shift_type_cd);
    const tmpKintaiDate = new Date(Kintai_bsc.date);
    
    const timePaidHolidayShift = Kintai_bsc.paid_holiday_shift_type_cd?Shift_msts.find((p)=>p.shift_type_cd === Kintai_bsc.paid_holiday_shift_type_cd):null;
    const time_paid_holiday_time_msec = timePaidHolidayShift?(timePaidHolidayShift.paid_holiday_time?timePaidHolidayShift.paid_holiday_time:0) * 60 * 60 * 1000:0;
    
    // 1:当日　2:翌日
    if(Shift_mst.shift_time_to_date_flg==='2'){
        tmpKintaiDate.setDate(new Date(tmpKintaiDate).getDate()+1);
    }

    const shiftMsecFrom = new Date(commonFnc.formatDate(Kintai_bsc.date) + " " + Shift_mst.shift_time_from);
    const shiftMsecTo = new Date(commonFnc.formatDate(tmpKintaiDate) + " " + Shift_mst.shift_time_to);

    let workMsecFromForCalc = workMsecFrom;
    let workMsecToForCalc = workMsecTo;
    
    // if(unitWorkTimeType==='1'){
    //     // シフト時間に丸める
    //     workMsecFromForCalc = workMsecFrom<shiftMsecFrom?shiftMsecFrom:workMsecFrom;
    //     workMsecToForCalc = workMsecTo>shiftMsecTo?shiftMsecTo:workMsecTo;
    // }

    const total_time = workMsecToForCalc - workMsecFromForCalc;

    let request_time = 0;
    
        request_time = (Kintai_bsc.rest_time ? Kintai_bsc.rest_time : 0)
        + (Kintai_bsc.work_time ? Kintai_bsc.work_time : 0)
        + (Kintai_bsc.over_time ? Kintai_bsc.over_time : 0)
        + (Kintai_bsc.midnight_over_time ? Kintai_bsc.midnight_over_time : 0)
        + (Kintai_bsc.training_time ? Kintai_bsc.training_time : 0);

    return total_time !== request_time

}

/**
 * シフトに設定された有給・公休時間を取得する。
 * @param {*} shift_type_cd 取得対象のシフトタイプCD
 * @param {*} Shift_msts シフト情報一覧
 * @param {*} targetName paid_haliday_time or public_holiday_time
 * @returns 
 */
export function getHolidayTime(shift_type_cd, Shift_msts, targetName){
    const shift = Shift_msts.find(p=>p.shift_type_cd === shift_type_cd);
    if(shift){
      return shift[targetName]?shift[targetName]:0;
    }else{
      return 0;
    }
  }