import { Button } from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import JarnalViewGrid from "../conponents/jarnalViewGrid.js";
import GenericTemplate from "../templates/GenericTemplate";
import { FormControl, InputLabel, makeStyles, MenuItem, Select, Tooltip } from "@material-ui/core";

const JarnalViewPage = (props) =>{
    
    const {
        setLogin_user_id,
        setKintai_bscs,
        Login_user_info,
        setDisplay_user_info,
        apiKey
    } = props;

    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);

    const[apiKeyStr, setApiKeyStr] = useState('paidHolidayJarnal');

    // const upsertApiUrl = `/api/upsert/${apiKey}`

    let keyName = "_id";


    useEffect(()=>{
            
        if(apiKeyStr === 'paidHolidayJarnal' ){
            keyName = "_id";
            setColumns(paidHolidayJarnalColumns);
        }else if(apiKeyStr === 'publicHolidayJarnal' ){
            keyName = "_id";
            setColumns(publicHolidayJarnalColumns);
        }

        const fetchDataFnc = async () =>{
            const selectApiUrl = `/api/select/${apiKeyStr}`
            const res = await fetch(selectApiUrl);
            const data = await res.json();
            setRows(data);
            console.log(data)
        }
        fetchDataFnc();
    },[apiKeyStr])

    // /**
    //  * マスタを更新する。
    //  */
    // const updateMst = async(rows) => {

    //     if(!rows){
    //         alert('データが存在しません。')
    //         return;
    //     }

    //     const result = window.confirm('マスタを更新しますか?');
    //     if(!result){
    //         return;
    //     }
    //     // if(hasDuplicateKey(rows)){
    //     //     alert('キーが重複しています。')
    //     //     return;
    //     // }

    //     const requestOptions = {
    //         method: 'POST',
    //         headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    //         body: JSON.stringify({mstData:rows})
    //     };
    //     await fetch(upsertApiUrl, requestOptions);
        
    // }

    
    // /**
    //  * 空白行を追加する。
    //  * @param {*} columns 
    //  * @param {*} rows 
    //  * @param {*} setRows 
    //  */
    // const addRow = (Login_user_info, keyName, columns, rows, setRows) => {

    //     if(!columns || !rows){
    //         alert('データが存在しません。')
    //         return;
    //     }

    //     const keyField = columns.find((p=>p.field === keyName));
    //     if(!keyField){
    //         alert('予期せぬ主キーが指定されました。');
    //         return;
    //     }

    //     const inputKey = window.prompt(`${keyField.headerName}を入力してください。`, "");
        
    //     if(!inputKey){
    //         return;
    //     }else if(rows.find((p)=>p[keyName]===inputKey)){
    //         alert('既に存在するキーです。');
    //         return;
    //     }

    //     const keyType = columns.find((p=>p.field === keyName))?.type;
    //     if(keyType==='number'){
    //         if(isNaN(inputKey)){
    //             alert('数字で入力してください。');
    //             return;
    //         }; 
    //     }

    //     let newRow = {};
    //     columns.forEach(col => {
    //         newRow[col.field] = null;
    //         if(col.field === 'CREATE_USER'){newRow[col.field] = Login_user_info.user_id}
    //         if(col.field === 'UPDATE_USER'){newRow[col.field] = Login_user_info.user_id} 
    //         if(col.field === 'LOG_DEL_FLG'){newRow[col.field] = 0} 
    //     });
    //     newRow[keyName] = inputKey;


    //     setRows([...rows,newRow])
    // }

    return (
        <GenericTemplate title="お知らせ一覧"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
        {!apiKey?<div class="loader">Loading...</div>:
        <div>
            <div class="titleArea">
                <FormControl>
                    <InputLabel id="view-label">表示履歴</InputLabel>
                    <Select
                    labelId="view-label"
                    onChange={e => setApiKeyStr(e.target.value)}
                    value={apiKeyStr}
                    defaultValue={"paidHolidayJarnal"}
                    >
                        <MenuItem value={'paidHolidayJarnal'}>有給付与履歴</MenuItem>
                        <MenuItem value={'publicHolidayJarnal'}>公休付与履歴</MenuItem>
                    </Select>
                    {/* <FormHelperText>勤務カレンダーでの文字色を指定できます。</FormHelperText> */}
                </FormControl>
            </div>
            {!rows?<></>:
            <JarnalViewGrid
                columns={columns}
                rows={rows}
                setRows={setRows}
                keyName={keyName}
            />}
        </div>}
        </GenericTemplate>
    );
}


// 有給付与情報用のカラム
const paidHolidayJarnalColumns = [
    {
        field: '_id',
        headerName: '_id',
        type: 'string',
        editable: false,
        align: 'center',
        headerAlign: 'left',
        width:'250',
    },
    {
        field: 'date',
        headerName: '日付',
        type: 'string',
        editable: false,
        align: 'center',
        headerAlign: 'left',
    },
    {
        field: 'user_id',
        headerName: 'ユーザID',
        type: 'string',
        editable: true,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'workyears',
        headerName: '勤続年数',
        type: 'string',
        editable: false,
        align: 'left',
        width:'100',
        headerAlign: 'left',
    },
    {
        field: 'work_style_cd',
        headerName: '勤務形態CD',
        type: 'string',
        editable: false,
        align: 'left',
        headerAlign: 'left',
    },
    {
        field: 'adddays_2yago',
        headerName: '2年前の加算予定日数',
        type: 'string',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        width:'180',
    },
    {
        field: 'getdays_2y',
        headerName: '2年間の取得日数',
        type: 'string',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        width:'180',
    },
    {
        field: 'adddays',
        headerName: '今年の加算予定日数',
        type: 'string',
        editable: true,
        align: 'left',
        headerAlign: 'left',
        width:'180',
    },
    {
        field: 'actually_adddays',
        headerName: '今年の付与日数',
        type: 'string',
        editable: false,
        align: 'left',
        headerAlign: 'left',
        width:'180',
    },
    {
        field: 'log_del_flg',
        headerName: '論理削除フラグ',
        type: 'string',
        editable: true,
        align: 'left',
        headerAlign: 'left',
        width:'180',
    },
];
// 公休付与情報用のカラム
const publicHolidayJarnalColumns = [
    {
        field: '_id',
        headerName: '_id',
        type: 'string',
        editable: false,
        align: 'center',
        headerAlign: 'left',
        width:'250',
    },
    {
        field: 'batchid',
        headerName: 'BATCHID',
        type: 'string',
        editable: false,
        align: 'center',
        headerAlign: 'left',
    },
    {
        field: 'datetime',
        headerName: 'datetime',
        type: 'string',
        editable: true,
        align: 'left',
        width:'400',
        headerAlign: 'left',
    },
    {
        field: 'date',
        headerName: '日付',
        type: 'string',
        editable: false,
        align: 'left',
        width:'100',
        headerAlign: 'left',
    },
    {
        field: 'time',
        headerName: '時刻',
        type: 'string',
        editable: false,
        align: 'left',
        width:'100',
        headerAlign: 'left',
    },
    {
        field: 'result_msg',
        headerName: 'メッセージ',
        type: 'string',
        editable: false,
        align: 'left',
        width:'400',
        headerAlign: 'left',
    },
    {
        field: 'result',
        headerName: '実行結果',
        type: 'string',
        editable: false,
        align: 'left',
        width:'600',
        headerAlign: 'left',
        valueGetter: (params) => {
            const r = params.row.result;
            if (typeof r === 'object' && r !== null) {
                return Object.keys(r).map(key => `${key}: ${r[key]}`).join(', ');
            }
            return;
        }
    }
];



export default JarnalViewPage;