import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, FormControl, InputLabel, makeStyles, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import * as commonFnc from '../common/commonFnc';
import * as BL_Valid from '../BL/BL_validate';
import * as BL_NFC from '../BL/BL_NFC';
import useSound from 'use-sound';
import SoundOk from '../audio/touch_ok2.mp3';
import SoundNg from '../audio/touch_ng1.mp3';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const useStyles = makeStyles({
  modalTextField:{
    margin: '5px',
    width: '100%',
  }
});


/**
 * ユーザ登録・編集用のモーダルフォーム
 * @param Login_user_info モーダルに表示するユーザ情報
 * @param User setUser Userのセッター
 * @param User setUser Userのセッター
 * @param Department_msts 部署情報
 * @param open モーダルの表示・非表示制御フラグ
 * @param setOpen openのセッター
 * @param beforeUser 修正前のユーザ情報
 * @param setUsers  ユーザ情報一覧のセッター
 * @returns 
 */
const DepMstMemberEditModal = (props) => {

  // 01_props
  const{
    Login_user_info, 
    User, 
    setUser, 
    Department_msts, 
    open, 
    setOpen, 
    beforeUser, 
    setUsers,
    public_holiday_type_msts,
    setPublic_holiday_type_msts,
  } = props;


  // 02_useState
  const [highly_department_cd, setHighly_department_cd] = useState(User?.organization_cd);
  const classes = useStyles()

  const paidHolidayRef = useRef(null);

  const [public_holiday_type_list, setPublic_holiday_type_list] = useState([]);
  
  const [idm, setIdm] = useState("");
  const [idmMsg, setIdmMsg] = useState("Idm読込");
  const [isDisable_nfc_button, setIsDisable_nfc_button] = useState(false);
  const [playOk, { stopOk, pauseOk }] = useSound(SoundOk);
  const [playNg, { stopNg, pauseNg }] = useSound(SoundNg);
  
  // 確認用
  const [idmCheck, setIdmCheck] = useState("");

  const [formErrors, setFormErrors] = useState({});
  useEffect(()=>{
    setFormErrors({})
  },[open])

  useEffect(()=>{
      var tmp_list = []
      if(public_holiday_type_msts.length > 0){
          public_holiday_type_msts
          .filter(p=>p.log_del_flg===false)
          .sort((a,b)=>(a.seq < b.seq ? -1 : 1))
          .map((item=>{
              tmp_list.push(<MenuItem value={item.public_holiday_type_cd}>{item.type_name}</MenuItem>);
          }))
      }
      setPublic_holiday_type_list(tmp_list);
  },[public_holiday_type_msts])

  useEffect(()=>{
    setHighly_department_cd(User?.organization_cd);
  },[User])


  // 03_独自関数(eventHandlerなど)
  const handleClose = () => {
    if(commonFnc.areObjectsEqual(beforeUser, User)){
      setOpen(false);
    }else{
      
      var result = window.confirm('編集中の情報があります。終了しますか？');
      if( !result ) return;
      setOpen(false);
      setUser({...User, user_id:null});

    }
    
  }
  
  const handleInputChage = (e) =>{
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setUser({ ...User, [name]: value });
  } 

  const handleOrganizationInputChage = (e) =>{
    setHighly_department_cd(e.target.value)
    handleInputChage(e)
  } 

  /**
   * 登録
   */
  const submitUserInfo = async () => {

    
    const errors = validate();
    if (Object.keys(errors).length !== 0) {
      setFormErrors(errors);
      return;
    }

    try {
        
      // 更新処理
      var url = "/api/update/user";
      const requestOptions ={
          method: 'POST',
          headers:{'Accept': 'application/json','Content-Type': 'application/json'},
          body: JSON.stringify({user_info: User})
      };
      await fetch(url,requestOptions);
      
      // ログ出力
      commonFnc.insertUserLog("DEBUG", Login_user_info.user_id, __filename, "ユーザ情報編集モーダル", "ユーザ情報更新処理", "更新処理", "更新ボタン押下", beforeUser, User);

      // ユーザ情報を再表示
      alert("ユーザ情報を登録しました。")
      const res_users = await fetch("api/select/users");
      const data = await res_users.json();
      setUsers(data);

      setOpen(false);

    } catch (error) {
      alert("ユーザ情報の登録に失敗しました。ブラウザを閉じてやり直してください。");
    }

  }

  // 削除
  const delIdm = () =>{
    var result = window.confirm('idm情報を削除しますか？');
    if( !result ) return;
 
    setUser({...User, idm:null});
    alert('idm情報を削除しました。登録確定をしてください。')
  }

  /**
 * Felicaの読み込みを待機する。
 */
  const waitNfc = async ()=>{
    let device;
    try {
      
      const ud = await navigator.usb.getDevices() ;	// ペアリング設定済みデバイスのUSBDeviceインスタンス取得
      let pearedDevices = null ;
      if(ud){
        pearedDevices = ud.filter((p)=>p.vendorId === 0x054c);
      }

      // ペアリング済みのデバイスが1台だけならそのデバイスと接続。
      if(pearedDevices.length === 1){
        device = pearedDevices[0];
      }else{
        device = await navigator.usb.requestDevice({ filters: [{ vendorId: 0x054c }] })
      }
      
      console.log("open");
      await device.open();
    } catch (e) {
      console.log(e);
      alert(e);
      throw e;
    }
    try {
      console.log("selectConfiguration");
      await device.selectConfiguration(1);
      console.log("claimInterface");
      await device.claimInterface(0);
      let i = 0;
      do {
        if(i>10) break;
        const idm = await BL_NFC.session(device);
        if(idm?.length > 0){
          setIdmCheck(idm); // 確認用
          
          const query = new URLSearchParams({ idm: idm });
          const res = await fetch(`/api/select/user_from_idm?${query}`);
          const tmp_user = await res.json();
          if(tmp_user){
            playNg();
            setIdmMsg(`${tmp_user.user_id}:${tmp_user.first_name} ${tmp_user.second_name}のカードです。`);
          }else{
            playOk();
            if(User.idm){
              var result = window.confirm('既にidmが登録されています。更新しますか？');
              if( !result ) continue; 
            }
            setUser({ ...User, idm: idm });
            break;
          }
          await BL_NFC.sleep(2000);
        }else{
          setIdmMsg("カードをかざしてください。");
          setIdm(idm);
        }
        setIsDisable_nfc_button(true);
        await BL_NFC.sleep(500);
        i++;
      } while (true);
      setIdmMsg("Idm読込");
      setIsDisable_nfc_button(false);
      device.close();
    } catch (e) {
      playNg();
      console.log(e);
      alert(e);
      try {
        device.close();
      } catch (e) {
        console.log(e);
      }
      // throw e;
    }
  }


 

  const validate = () => {
    let errors = {};

    BL_Valid.isValidRequired(errors, User, "first_name");
    BL_Valid.isValidRequired(errors, User, "second_name");
    BL_Valid.isValidRequired(errors, User, "first_kana_name");
    BL_Valid.isValidRequired(errors, User, "second_kana_name");

    BL_Valid.isValidRequired(errors, User, "entrer_date");
    BL_Valid.isValidDate(errors, User, "entrer_date");

    if(User.retire_date){
      BL_Valid.isValidDate(errors, User, "retire_date");
    }

    BL_Valid.isValidRequired(errors, User, "start_paid_holiday_add_day");
    BL_Valid.isValidDate(errors, User, "start_paid_holiday_add_day");
    
    BL_Valid.isValidRequired(errors, User, "fixed_overtime");
    BL_Valid.isValidNumber(errors, User, "fixed_overtime");

    BL_Valid.isValidRequired(errors, User, "enable_public_holiday_days");
    BL_Valid.isValidNumber(errors, User, "enable_public_holiday_days");

    BL_Valid.isValidRequired(errors, User, "enable_paid_holiday_days");
    BL_Valid.isValidNumber(errors, User, "enable_paid_holiday_days");

    return errors;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            ユーザ情報
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <div className='modal_float'>
              <TextField label="職員番号" variant="outlined" name="user_id" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={User.user_id} disabled/>
              <TextField label="PW" variant="outlined" name="pw" className={classes.modalTextField} type="password" onChange={e => handleInputChage(e)} value={User.pw} required />
            
            </div>
            <div className='modal_float'>
              <TextField 
                label="氏" variant="outlined" name="first_name" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.first_name}
                error={!!formErrors.first_name}
                helperText={formErrors.first_name}
                required
              />
              <TextField 
                label="名" variant="outlined" name="second_name" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.second_name}
                error={!!formErrors.second_name}
                helperText={formErrors.second_name}
                required
              />
              <TextField 
                label="かな氏" variant="outlined" name="first_kana_name" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.first_kana_name}
                error={!!formErrors.first_kana_name}
                helperText={formErrors.first_kana_name}
                required
              />
              <TextField 
                label="かな名" variant="outlined" name="second_kana_name" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.second_kana_name}
                error={!!formErrors.second_kana_name}
                helperText={formErrors.second_kana_name}
                required
              />
            </div>
            <div className='modal_float modal_spacer'>
              <TextField 
                label="入職日" InputLabelProps={{ shrink: true }} variant="outlined" name="entrer_date" className={classes.modalTextField} 
                type="date" 
                onChange={e => handleInputChage(e)} 
                value={User.entrer_date}
                error={!!formErrors.entrer_date}
                helperText={formErrors.entrer_date}
                required
              />
              <TextField 
                label="退職日" InputLabelProps={{ shrink: true }} variant="outlined" name="retire_date" className={classes.modalTextField} 
                type="date" 
                onChange={e => handleInputChage(e)} 
                value={User.retire_date}
                error={!!formErrors.retire_date}
                helperText={formErrors.retire_date}
              />
              <TextField 
                label="有給起算日" InputLabelProps={{ shrink: true }} variant="outlined" name="start_paid_holiday_add_day" className={classes.modalTextField} 
                type="date" 
                onChange={e => handleInputChage(e)} 
                value={User.start_paid_holiday_add_day}
                error={!!formErrors.start_paid_holiday_add_day}
                helperText={formErrors.start_paid_holiday_add_day}
                required
              />
              <TextField 
                label="みなし残業時間" variant="outlined" name="fixed_overtime" className={classes.modalTextField} 
                type="number" 
                onChange={e => handleInputChage(e)} 
                value={User.fixed_overtime}
                required
              />
            </div>
            <div className='modal_float'>
              <TextField 
                label="一次承認者(個人)" variant="outlined" name="first_approval_userid" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.first_approval_userid}
              />
              <TextField 
                label="二次承認者(個人)" variant="outlined" name="second_approval_userid" className={classes.modalTextField} 
                onChange={e => handleInputChage(e)} 
                value={User.second_approval_userid}
              />
              <TextField 
                label="公休残数" variant="outlined" name="enable_public_holiday_days" className={classes.modalTextField} 
                type="number" 
                onChange={e => handleInputChage(e)} 
                value={User.enable_public_holiday_days} 
                required
              />
              <TextField 
                label="有給残数" variant="outlined" name="enable_paid_holiday_days" className={classes.modalTextField} 
                type="number" 
                onChange={e => handleInputChage(e)} 
                value={User.enable_paid_holiday_days} 
                required
              />
            </div>
            <div className='modal_float modal_spacer'>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="organization_cd-label">組織</InputLabel>
              <Select
                onChange={e => handleOrganizationInputChage(e)}
                name="organization_cd"
                defaultValue={User.organization_cd}
                value={User.organization_cd}
                variant="outlined"
                labelId="organization_cd-label"
                className='modalSelectField'
              >
                {Department_msts.filter((p)=>p.department_type===3).map((depMst, index) =>(
                  <MenuItem value={depMst.department_cd}>{depMst.department_cd}  :  {depMst.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="department_cd-label">部署</InputLabel>
              <Select
                onChange={e => handleInputChage(e)}
                name="department_cd"
                defaultValue={User.department_cd}
                value={User.department_cd}
                variant="outlined"
                labelId="department_cd-label"
                className='modalSelectField'
                required
              >
                {Department_msts.filter((p)=>p.department_type===5 && p.highly_department_cd === highly_department_cd).map((depMst, index) =>(
                  <MenuItem value={depMst.department_cd}>{depMst.department_cd}  :  {depMst.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="authority-label">無効フラグ</InputLabel>
              <Select
                labelId="authority-label"
                variant="outlined"
                name="log_del_flg"
                className='modalSelectField'
                onChange={e => handleInputChage(e)}
                defaultValue={User.log_del_flg}
                value={User.log_del_flg}
                >
                  <MenuItem value={false}>有効</MenuItem>
                  <MenuItem value={true}>無効</MenuItem>
              </Select>
            </FormControl>
            </div>
            <div className='modal_float'>
              <FormControl className="modalFormControl" required>
                <InputLabel className="InputLabel-style modalInputLabel-style" id="employment_status_cd-label">雇用形態</InputLabel>
                <Select
                  labelId='employment_status_cd-label'
                  variant="outlined"
                  name="employment_status_cd"
                  className='modalSelectField'
                  onChange={e => handleInputChage(e)}
                  defaultValue={User.employment_status_cd}
                  >
                    <MenuItem value="1">正職員</MenuItem>
                    <MenuItem value="2">フルタイムパート</MenuItem>
                    <MenuItem value="3">パート</MenuItem>
                    <MenuItem value="4">Wワーク</MenuItem>
                </Select>
            </FormControl>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="work_style_cd-label">勤務形態</InputLabel>
              <Select
                labelId="work_style_cd-label"
                variant="outlined"
                name="work_style_cd"
                className='modalSelectField'
                onChange={e => handleInputChage(e)}
                defaultValue={User.work_style_cd}
                value={User.work_style_cd}
                >
                  <MenuItem value="101">週5日以上</MenuItem>
                  <MenuItem value="201">週4日</MenuItem>
                  <MenuItem value="202">週3日</MenuItem>
                  <MenuItem value="203">週2日</MenuItem>
                  <MenuItem value="204">週1日</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="authority-label">権限</InputLabel>
              <Select
                labelId="authority-label"
                variant="outlined"
                name="authority"
                className='modalSelectField'
                onChange={e => handleInputChage(e)}
                defaultValue={User.authority}
                value={User.authority}
                >
                  <MenuItem value={10}>一般ユーザ</MenuItem>
                  <MenuItem value={15}>承認者</MenuItem>
                  <MenuItem value={20}>管理者</MenuItem>
              </Select>
            </FormControl>
            <FormControl className="modalFormControl" required>
              <InputLabel className="InputLabel-style modalInputLabel-style" id="public_holiday_type_cd-label">公休タイプCD</InputLabel>
              <Select
                labelId="public_holiday_type_cd-label"
                variant="outlined"
                name="public_holiday_type_cd"
                className='modalSelectField'
                onChange={e => handleInputChage(e)}
                defaultValue={User.public_holiday_type_cd}
                value={User.public_holiday_type_cd}
                >
                  {public_holiday_type_list}
              </Select>
            </FormControl>
            </div>
            <div className='modal_float'>
              <TextField label="備考" multiline variant="outlined" name="notes" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={User.notes}/>
            </div>
            <div className='modal_float modal_spacer'>
              <TextField label="連携コード" variant="outlined" name="if_cd" className={classes.modalTextField} onChange={e => handleInputChage(e)} value={User.if_cd}/>
              <TextField label="idm" variant="outlined" name="idm" className={classes.modalTextField} value={User.idm? User.idm:""} disabled/>
            </div>
          </Typography>
          <div>
            {User.idm?
            <Button variant='contained' color='secondary' className={classes.modalTextField} onClick={()=>delIdm()} disabled={isDisable_nfc_button}>
              idm削除
            </Button>
            :
            <Button variant='contained' color='primary' className={classes.modalTextField} onClick={()=>waitNfc()} disabled={isDisable_nfc_button}>
              <div class="nfc_loader small_loader" hidden={!isDisable_nfc_button || idm}></div>
              <div>{idmMsg}</div>
            </Button>
            }
          </div>
          <Button variant='contained' color='primary' className={classes.modalTextField} onClick={()=>submitUserInfo()}>登録</Button>
          {/* <Button variant='contained' color='primary' className={classes.modalTextField} type="submit">登録</Button> */}
          {/* 確認用 */}
          <div className='modal_float'>
            <TextField label="readingIdm" disabled multiline variant="outlined" name="notes" className={classes.modalTextField} value={idmCheck}/>
          </div>
        </Box>
    </Modal>
  );
}
export default DepMstMemberEditModal;