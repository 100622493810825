import * as commonFnc from '../common/commonFnc';
import * as BL_kintai from '../BL/BL_kintai';



/**
 * 対象者の勤怠を承認する。
 * @param {*} urlPrefix 取得元のテーブル名
 * @param {*} target_kintai_bsc 対象の勤怠情報
 * @param {*} Department_msts 部署情報一覧
 * @param {*} setKintai_bscs 勤怠情報のセッター
 * @param {*} login_user_id ログインユーザID
 * @returns 
 */
export async function requestApproval(urlPrefix, target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id) {

    // ユーザ情報を取得し、次回承認者と次回承認CDを更新する。
    const query = new URLSearchParams({user_id: target_kintai_bsc.user_id});

    let res = await fetch(`/api/select/user?${query}`);
    let targetUserData = await res.json();
    let approvalOrder = BL_kintai.getApprovalOder(Department_msts, targetUserData, login_user_id);
    let nextid = BL_kintai.getNextApprovalUser(targetUserData, Department_msts, target_kintai_bsc.approval_status_cd);
    let lastid = login_user_id;
    let nextcd = BL_kintai.getNextApprovalCd(target_kintai_bsc.approval_status_cd, approvalOrder);
    let approval_note = target_kintai_bsc.approval_note?target_kintai_bsc.approval_note:"";
    
    await updateKintaiApprovalInfo(
        urlPrefix,
        login_user_id,
        setKintai_bscs,
        target_kintai_bsc.user_id,
        target_kintai_bsc.date,
        nextid,
        lastid,
        nextcd,
        approval_note,
    );

    return 1;
}

// summary: 差戻しをする。
// param : Kintai_bsc target_kintai_bsc 対象の勤怠情報
// param : Array Department_msts 部署情報一覧
// param : Kintai_bsc setKintai_bscs 勤怠情報のセッター
// return : なし
export async function requestReturn(urlPrefix, target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id) {

    // ユーザ情報を取得し、次回承認者と次回承認CDを更新する。
    const query = new URLSearchParams({user_id: target_kintai_bsc.user_id});

    let res = await fetch(`/api/select/user?${query}`);
    let targetUserData = await res.json();
    // let nextid = BL_kintai.getNextApprovalUser(targetUserData, Department_msts, target_kintai_bsc.approval_status_cd);
    let nextid = BL_kintai.getNextApprovalUser(targetUserData, Department_msts, "back");
    let lastid = login_user_id;
    let nextcd = "80"
    
    await updateKintaiApprovalInfo(
        urlPrefix,
        login_user_id,
        setKintai_bscs,
        target_kintai_bsc.user_id,
        target_kintai_bsc.date,
        nextid,
        lastid,
        nextcd,
        target_kintai_bsc.approval_note
    );

    return 1;
}

// summary: 取消申請をする。
// param : Kintai_bsc target_kintai_bsc 対象の勤怠情報
// param : Array Department_msts 部署情報一覧
// param : Kintai_bsc setKintai_bscs 勤怠情報のセッター
// return : なし
export async function requestCancel (urlPrefix, target_kintai_bsc, Department_msts, setKintai_bscs, login_user_id) {

    let nextid = login_user_id;
    let lastid = "";
    let nextcd = ""

    if(target_kintai_bsc.first_approval_userid === login_user_id){
        nextcd = "20";
        lastid = "";
    }else if(target_kintai_bsc.second_approval_userid === login_user_id){
        nextcd = "30";
        lastid = target_kintai_bsc.first_approval_userid;
    }else{
        nextcd = "99";
        lastid = "err";
    }

    // 承認者コメントはクリア
    await updateKintaiApprovalInfo(
        urlPrefix,
        login_user_id,
        setKintai_bscs,
        target_kintai_bsc.user_id,
        target_kintai_bsc.date,
        nextid,
        lastid,
        nextcd,
        ""
    );

    return 1;
}

/**
 * 勤怠テーブルに承認状況を登録する。
 * @param {*} urlPrefix 取得元のテーブル名　kintai_bsc or approval_kintai_bsc
 * @param {*} login_user_id ログインユーザID
 * @param {*} setKintai_bscs 勤怠情報のセッター
 * @param {*} target_user_id 承認対象者のユーザID
 * @param {*} target_date 承認対象日
 * @param {*} target_next_approval_userid 次回承認者
 * @param {*} target_last_approval_userid 最終承認者
 * @param {*} target_approvalcd 承認後の承認CD
 * @param {*} target_approval_note 承認コメント
 */
export async function updateKintaiApprovalInfo(urlPrefix, login_user_id, setKintai_bscs,target_user_id, target_date, target_next_approval_userid, target_last_approval_userid, target_approvalcd, target_approval_note){

    // 勤怠情報の承認CDと次回承認者を更新する。
    const bodyParam = new URLSearchParams({
        user_id:target_user_id, 
        date:commonFnc.formatDate(target_date), 
        next_approval_userid: target_next_approval_userid, 
        last_approval_userid: target_last_approval_userid, 
        approval_status_cd: target_approvalcd,
        approval_note: target_approval_note===null?"":target_approval_note,
    });

    const params = {method : "POST", body : bodyParam};
    await fetch(`/api/insert/kintai_bsc2`, params);
    
    // 画面表示を更新する。
    // 承認後はログインユーザの承認対象者一覧を取得する
    // const queryKintai = new URLSearchParams({login_user_id: urlPrefix==="approval_kintai_bsc" ? login_user_id : target_user_id});
    // const urlStr = `/api/select/${urlPrefix}?${queryKintai}`
    // const res = await fetch(urlStr);
    // const data = await res.json();
    // setKintai_bscs(data);

}

