export class Export_csv {

    constructor(){
        this.csv_id = ""; // 識別コード
        this.department_cd = ""; // 部門コード
        this.user_id = ""; // 従業員コード
        this.user_name = ""; // 従業員氏名
        this.work_days = 0; // 出勤日数
        this.real_work_days = 0; // 出勤実日数
        this.absence_days = 0; // 欠勤日数
        this.public_holiday_days = 0; // 公休日数
        this.paid_holiday_days = 0; // 有給日数
        this.sick_leave_days = 0; // 病欠日数
        this.holiday_work_days = 0; // 休日出勤日数
        this.early_time = 0; // 早退時間
        this.behind_time = 0; // 遅刻時間
        this.early_behind_time = 0; // 遅刻早退時間
        this.over_time = 0; // 残業時間
        this.rest_time = 0; // 休憩時間
        this.cafeterias = 0; // 食事回数
        this.midnight_work_time = 0; // 深夜勤務時間
        this.midnight_over_time = 0; // 深夜残業時間
        this.holiday_work_time = 0; // 休日勤務時間
        this.holiday_over_time = 0; // 休日残業時間
        this.holiday_midnight_over_time = 0; // 休日深夜残業時間
        this.extra_time = 0; // 割増勤務時間
        this.work_time = 0; // 勤務時間
        this.night_work_times = 0; // 夜勤回数
        this.night_watch_work_times = 0; // 宿直回数
        this.public_holiday_time = 0; // 公休日数
        this.paid_holiday_time = 0; // 有給時間数
        this.time_paid_holiday_time = 0; // 時間有給時間数
        this.day_paid_holiday_time = 0; // 有休取得時間数の内、時間有給以外の時間
        this.fixed_over_time = 0; // みなし残業時間
        this.count1 = 0; // 汎用フラグ1
        this.count2 = 0; // 汎用フラグ2
        this.count3 = 0; // 汎用フラグ3
        this.count4 = 0; // 汎用フラグ4
        this.count5 = 0; // 汎用フラグ5
        this.user_note1 = ""; // 従業員備考
    }

    incrementWork_days(){
        this.work_days++;
    }
    incrementReal_Work_days(){
        this.real_work_days++;
    }
    incrementAbsence_days(){
        this.absence_days++;
    }
    incrementPublic_holiday_days(){
        this.public_holiday_days++;
    }
    incrementPaid_holiday_days(){
        this.paid_holiday_days++;
    }
    incrementSick_leave_days(){
        this.sick_leave_days++;
    }
    incrementHoliday_work_days(){
        this.holiday_work_days++;
    }
    incrementCafeterias(){
        this.cafeterias++;
    }
    incrementNight_work_times(){
        this.night_work_times++;
    }
    incrementNight_watch_work_times(){
        this.night_watch_work_times++;
    }
    incrementCount1(){
        this.count1++;
    }
    incrementCount2(){
        this.count2++;
    }
    incrementCount3(){
        this.count3++;
    }
    incrementCount4(){
        this.count4++;
    }
    incrementCount5(){
        this.count5++;
    }
    addWork_time(work_time){
        this.work_time += work_time;
    }
    addOver_time(over_time){
        this.over_time += over_time;
    }
    addMidnight_over_time(midnight_over_time){
        this.midnight_over_time += midnight_over_time;
    }
    addMidnight_work_time(midnight_work_time){
        this.midnight_work_time += midnight_work_time;
    }
    addRest_time(rest_time){
        this.rest_time += rest_time;
    }
    addEarly_time(early_time){
        this.early_time += early_time;
    }
    addBehind_time(behind_time){
        this.behind_time += behind_time;
    }
    addEarly_behind_time(early_behind_time){
        this.early_behind_time += early_behind_time;
    }
    addPublic_holiday_time(public_holiday_time){
        this.public_holiday_time += public_holiday_time;
    }
    addPaid_holiday_time(paid_holiday_time){
        this.paid_holiday_time += paid_holiday_time;
    }
    addFixed_over_time(fixed_over_time){
        this.fixed_over_time += fixed_over_time;
    }
    addHoliday_work_time(holiday_work_time){
        this.holiday_work_time += holiday_work_time;
    }
    addHoliday_over_time(holiday_over_time){
        this.holiday_over_time += holiday_over_time;
    }
    addHoliday_midnight_over_time(holiday_midnight_over_time){
        this.holiday_midnight_over_time += holiday_midnight_over_time;
    }
    addtime_paid_holiday_time(time_paid_holiday_time){
        this.time_paid_holiday_time += time_paid_holiday_time;
    }
    addAny(name, value){
        if(name){
            // this[name] += parseInt(value);
            this[name] += value;
        }
    }


    get getCsv_Id(){
        return 	this.Csv_Id;
    }
    
    set setCsv_Id(csv_id){
        this.csv_id=csv_id;
    }
    
    get getDepartment_Cd(){
        return 	this.Department_Cd;
    }
    
    set setDepartment_Cd(department_cd){
        this.department_cd=department_cd;
    }
    
    get getUser_Id(){
        return 	this.User_Id;
    }
    
    set setUser_Id(user_id){
        this.user_id=user_id;
    }
    
    get getUser_Name(){
        return 	this.User_Name;
    }
    
    set setUser_Name(user_name){
        this.user_name=user_name;
    }
    
    get getWork_Days(){
        return 	this.Work_Days;
    }
    
    set setWork_Days(work_days){
        this.work_days=work_days;
    }
    
    get getAbsence_Days(){
        return 	this.Absence_Days;
    }
    
    set setAbsence_Days(absence_days){
        this.absence_days=absence_days;
    }
    
    get getPublic_Holiday_Days(){
        return 	this.Public_Holiday_Days;
    }
    
    set setPublic_Holiday_Days(public_holiday_days){
        this.public_holiday_days=public_holiday_days;
    }
    
    get getPaid_Holiday_Days(){
        return 	this.Paid_Holiday_Days;
    }
    
    set setPaid_Holiday_Days(paid_holiday_days){
        this.paid_holiday_days=paid_holiday_days;
    }
    
    get getSick_Leave_Days(){
        return 	this.Sick_Leave_Days;
    }
    
    set setSick_Leave_Days(sick_leave_days){
        this.sick_leave_days=sick_leave_days;
    }
    
    get getHoliday_Work_Days(){
        return 	this.Holiday_Work_Days;
    }
    
    set setHoliday_Work_Days(holiday_work_days){
        this.holiday_work_days=holiday_work_days;
    }
    
    get getEarly_Time(){
        return 	this.Early_Time;
    }
    
    set setEarly_Time(early_time){
        this.early_time=early_time;
    }
    
    get getBehind_Time(){
        return 	this.Behind_Time;
    }
    
    set setBehind_Time(behind_time){
        this.behind_time=behind_time;
    }
    
    get getEarly_Behind_Time(){
        return 	this.Early_Behind_Time;
    }
    
    set setEarly_Behind_Time(early_behind_time){
        this.early_behind_time=early_behind_time;
    }
    
    get getOver_Time(){
        return 	this.Over_Time;
    }
    
    set setOver_Time(over_time){
        this.over_time=over_time;
    }
    
    get getCafeterias(){
        return 	this.Cafeterias;
    }
    
    set setCafeterias(cafeterias){
        this.cafeterias=cafeterias;
    }
    
    get getMidnight_Over_Time(){
        return 	this.Midnight_Over_Time;
    }
    
    set setMidnight_Over_Time(midnight_over_time){
        this.midnight_over_time=midnight_over_time;
    }
    
    get getHoliday_Over_Time(){
        return 	this.Holiday_Over_Time;
    }
    
    set setHoliday_Over_Time(holiday_over_time){
        this.holiday_over_time=holiday_over_time;
    }
    
    get getExtra_Time(){
        return 	this.Extra_Time;
    }
    
    set setExtra_Time(extra_time){
        this.extra_time=extra_time;
    }
    
    get getWork_Time(){
        return 	this.Work_Time;
    }
    
    set setWork_Time(work_time){
        this.work_time=work_time;
    }
    
    get getNight_Work_Times(){
        return 	this.Night_Work_Times;
    }
    
    set setNight_Work_Times(night_work_times){
        this.night_work_times=night_work_times;
    }
}