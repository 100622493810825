import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles/';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    maxHeight: '60vh',
    overflow: 'auto'    
  },
}));

const RequestShiftMstList = (props) => {
  const classes = useStyles();
  const {
    Shift_msts, 
    setShift_msts, 
    Shift_mst,
    setShift_mst, 
    isViewLogDelShift, 
    setIsLogDelShift, 
    Login_user_info,
    Department_msts,
    organization_cd,
    setOrganization_cd,
    department_cd,
    setDepartment_cd
  } = props;

  const [Shift_mstId, settgtShift_mstId] = useState("");

  
  const handleInputChage = (e) =>{
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setShift_mst({ ...Shift_mst, [name]: value });
  } 
  const handleOrganizationInputChage = (e) =>{
    setOrganization_cd(e.target.value);
    handleInputChage(e)
  } 

  
  const handleChageListItem = (shift_type_cd) =>{
    setShift_mst({
        shift_type_cd: "",
        shift_name: "",
        shift_time_from: "",
        shift_time_to: "",
        rest_time: "",
        use_day_from: "",
        use_day_to: "",
        shift_group_cd:"",
        public_holiday_time:"",
        paid_holiday_time:"",
        note: "",
        seq: "",
        backgroundcolor: "",
        textcolor: "",
        shift_switching_time:"",
        shift_time_to_date_flg:"1",
        b_a_cd:"",
        department_cd:"",
    });
    settgtShift_mstId(Shift_msts.find(p=>p.shift_type_cd === shift_type_cd)._id);
    setShift_mst(Shift_msts.find(p=>p.shift_type_cd === shift_type_cd));
  }

  const newShist_mst = () =>{
      setShift_mst({
          shift_type_cd: "",
          shift_name: "",
          shift_time_from: "",
          shift_time_to: "",
          rest_time: "",
          use_day_from: "",
          use_day_to: "",
          shift_group_cd:"",
          public_holiday_time:"",
          paid_holiday_time:"",
          note: "",
          seq: "",
          backgroundcolor: "",
          textcolor: "",
          shift_switching_time:"",
          shift_time_to_date_flg:"1",
          b_a_cd:"",
          department_cd:department_cd?department_cd:organization_cd==="all"?"":organization_cd,
      });
  }

  const deleteShift_mst = ()=>{
    
    var result = window.confirm('選択したシフトを削除しますか？過去の勤怠情報に登録されている場合、シフト情報が参照できなくなります。');
    if( !result ) {
      return;
    }

    const bodyParam = new URLSearchParams({_id: Shift_mstId});
    const params = {method : "POST", body : bodyParam};

    fetch(`/api/delete/shift_mst`, params)
    .then(
      fetch("/api/select/shift_mst")
      .then((res) => res.json())
      .then((data) => setShift_msts(data))
    )
    alert('削除完了')
  }



  return (
    <div className={classes.root}>
      <div>
          <input hidden readOnly name="_id" value={Shift_mstId}/>
          <Button className="acction_button" variant="outlined" onClick={newShist_mst} style={{marginTop:"7px", width:"45%"}}>新規作成</Button>
          {Login_user_info.authority!==90?"":
            <Button className="acction_button" variant="outlined" onClick={deleteShift_mst} style={{marginTop:"7px", width:"45%"}}>削除</Button>
          }
      </div>
      <FormControl className="modalFormControl" style={{ minWidth: "350px" }}>
        <InputLabel className="InputLabel-style modalInputLabel-style" id="organization_cd-label">組織</InputLabel>
        <Select
          onChange={e => setOrganization_cd(e.target.value)}
          name="department_cd"
          defaultValue={organization_cd}
          value={organization_cd}
          variant="outlined"
          labelId="organization_cd-label"
          // inputProps={{ style: { width: "400px" } }}
          
        >
          <MenuItem value={"all"}>共通</MenuItem>
          {Department_msts.filter((p)=>p.department_type===3).map((depMst, index) =>(
            <MenuItem value={depMst.department_cd}>{depMst.department_cd}  :  {depMst.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl className="modalFormControl" style={{ minWidth: "350px" }}>
        <InputLabel className="InputLabel-style modalInputLabel-style" id="department_cd-label">部署</InputLabel>
        <Select
          onChange={e => setDepartment_cd(e.target.value)}
          name="department_cd"
          defaultValue={department_cd}
          value={department_cd}
          variant="outlined"
          labelId="department_cd-label"
          className='modalSelectField'
          required
        >
          <MenuItem value={""}>未選択</MenuItem>
          {Department_msts.filter((p)=>p.department_type===5 && p.highly_department_cd === organization_cd).map((depMst, index) =>(
            <MenuItem value={depMst.department_cd}>{depMst.department_cd}  :  {depMst.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <div>
        <FormControlLabel
            control={<Switch checked={isViewLogDelShift} onChange={()=>setIsLogDelShift(!isViewLogDelShift)} />}
            label="無効シフトも表示"
        />
      </div>
      <div>
        　 並び　｜　　CD　｜　名称
        <List component="nav" className={classes.list}>
            {Shift_msts
              .filter(p=>organization_cd!=="all" ? p.department_cd===(department_cd?department_cd:organization_cd) : !p.department_cd)
              .filter(p=>p.log_del_flg === false || p.log_del_flg === isViewLogDelShift).sort((a, b) => a.seq < b.seq ? -1 : 1).map((shift_mst, index)=>(
                <ListItem button onClick={()=>handleChageListItem(shift_mst.shift_type_cd)} key={index} className={shift_mst.log_del_flg ? "del" : "use"}>
                    <ListItemText primary={`${('000' + shift_mst.seq).slice(-3)}　｜　${shift_mst.shift_type_cd}　｜　${shift_mst.shift_name}`} />
                </ListItem>
            ))}
        </List>
      </div>
    </div>
  );
}
export default RequestShiftMstList;