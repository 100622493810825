import { useContext, useEffect } from "react";
import {GlobalContext} from '../globalState'
import ImportShiftEditer from "../conponents/ImportShiftEditer";
import GenericTemplate from "../templates/GenericTemplate";
import '../css/Loading.css';


const ImportShiftPage = (props) =>{
    const {
      setLogin_user_id,
      setKintai_bscs,
      Login_user_info,
      setDisplay_user_info,

      Shift_msts,
      setShift_msts,
      Department_msts,
      setDepartment_msts,
      Users,
      setUsers,
      setLogin_user_info,
    } = props;

    // CLOCKIN_MODE  TOUCH:タッチで打刻 NFC:NFCで打刻
    const Env_msts = useContext(GlobalContext).Env_msts;

    useEffect(()=>{
      fetch("api/select/users")
      .then((res)=> res.json())
      .then((data)=>setUsers(data));
      
      fetch("/api/select/shift_mst")
      .then((res) => res.json())
      .then((data) => setShift_msts(data));
      
      fetch("/api/select/department_msts")
      .then((res) => res.json())
      .then((data) => setDepartment_msts(data))
    },[])


    return (
      <GenericTemplate title="勤務シフトCSV取込"
          setLogin_user_id={setLogin_user_id}
          setKintai_bscs={setKintai_bscs}
          Login_user_info={Login_user_info}
          setDisplay_user_info={setDisplay_user_info}
      >
              <div>
              {!Env_msts?<div class="loader">Loading...</div>:
                <div>
                  <ImportShiftEditer
                    Login_user_info={Login_user_info}
                    Shift_msts={Shift_msts}
                    Department_msts={Department_msts}
                    Users={Users}
                    setLogin_user_info={setLogin_user_info}
                  />
                </div>
              }
              </div>
        </GenericTemplate>
    );
}

export default ImportShiftPage;