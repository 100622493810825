import GenericTemplate from "../templates/GenericTemplate";
import '../css/Loading.css';
import ShiftTableContents from "../conponents/ShiftTableContents";

const ShiftTablePage = (props) =>{
    const {setLogin_user_id, setKintai_bscs, Login_user_info, setDisplay_user_info} = props;

    return (
        <GenericTemplate title="シフトテーブル"
            setLogin_user_id={setLogin_user_id}
            setKintai_bscs={setKintai_bscs}
            Login_user_info={Login_user_info}
            setDisplay_user_info={setDisplay_user_info}
        >
            <ShiftTableContents
                Login_user_info={Login_user_info}
            />
        </GenericTemplate>
    );
}

export default ShiftTablePage;