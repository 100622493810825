import { useContext } from "react";
import {GlobalContext} from '../globalState'
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button"; 
import { FormControl, InputLabel, makeStyles, MenuItem, Select, Tooltip } from "@material-ui/core";
import * as commonFnc from '../common/commonFnc';

const useStyles = makeStyles((theme)=>({
    root:{
        '& .MuiTextField-root':{
            margin: theme.spacing(1),
            width: 200,
        }
    }
}))


const RequestShiftMstForm = (props) =>{
    const {
        Login_user_info,
        setShift_msts,
        Shift_mst, 
        setShift_mst,
        Department_msts
    } = props;
    const classes = useStyles();

    const {shiftGroupInfo, setShiftGroupInfo} = useContext(GlobalContext);
    const shiftGroupCdList = ["10","20","30","40","45","50","60","61","62","90","91","99"];

    const handleInputChage = (e) =>{
        const target = e.target;
        const value = target.value;
        const name = target.name;
        setShift_mst({ ...Shift_mst, [name]: value });
    } 

    const handleInputChageConvertHHmmToMsec = (e) =>{
        const target = e.target;
        const value = commonFnc.getMsec(target.value);
        const name = target.name;
        setShift_mst({ ...Shift_mst, [name]: value });
    } 
    

    /**
     * 【単体】当翌フラグを切り替える
     * @param {*} e 
     * @param {*} setObj 
     * @param {*} Obj 
     */
    const handleChageDayFlg = (e, setObj, Obj) =>{

        var name = e.target.name;
        var time = document.getElementById(name).value;
        var day = e.target.value;

        setObj({
            ...Obj,
            [name]: new Date(day + " " + time)
        })
        
    }

    const handleSubmit = async () =>{

        if(!Shift_mst.shift_type_cd){
            alert('シフトタイプCDが登録されていません。');
            return;
        }

        // シフト情報登録 
        var url = "/api/update/shift_mst"
        var bodyData = {...Shift_mst};
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            body: JSON.stringify(bodyData)
        };

        try {
            const res = await fetch(url, requestOptions);
            if (res.ok) {

                // ログ出力
                commonFnc.insertUserLog("INFO", Login_user_info.user_id, __filename, "シフトマスタ登録", "シフト登録処理", "登録処理", "", null, bodyData);

                // シフトマスタ一覧取得
                const res = await fetch("/api/select/shift_mst");
                const data = await res.json();    
                setShift_msts(data);
                
                alert('登録完了');

            } else {
                // リクエストが失敗した場合の処理
            }
        } catch (error) {
            console.log(error)
        }


    }
    
    return(
        // <form action="/api/update/shift_mst" method="POST" className={classes.root}>
        // <form className={classes.root} onSubmit={e=>handleSubmit(e)}>
        <div className={classes.root}>
            <div className="request-form" noValidate>
                <div>
                    <TextField label="シフト種別CD" variant="outlined" name="shift_type_cd" onChange={e => handleInputChage(e)} value={Shift_mst.shift_type_cd===undefined? "" : Shift_mst.shift_type_cd} required/>
                    <TextField label="名称" variant="outlined" name="shift_name" onChange={e => handleInputChage(e)} value={Shift_mst.shift_name===undefined? "" : Shift_mst.shift_name}/>
                </div>
                <div>
                    <TextField InputLabelProps={{ shrink: true }} label="開始時間" variant="outlined" name="shift_time_from" type="time" inputProps={{ step: 900}} onChange={e => handleInputChage(e)} value={Shift_mst.shift_time_from===undefined? "" : Shift_mst.shift_time_from}/>
                    <FormControl className="shiftmstFormControl">
                        <Select
                        name="shift_time_to_date_flg"
                        variant="outlined"
                        onChange={e=> handleInputChage(e)}
                        defaultValue={'1'}
                        value={Shift_mst.shift_time_to_date_flg}
                        >
                            <MenuItem value={'1'}>当</MenuItem>
                            <MenuItem value={'2'}>翌</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField InputLabelProps={{ shrink: true }} label="終了時間" variant="outlined" name="shift_time_to" type="time" style={{width:'125px'}}
                        inputProps={{ step: 900}} onChange={e => handleInputChage(e)} value={Shift_mst.shift_time_to===undefined? "" : Shift_mst.shift_time_to}/>
                </div>
                <div>
                    <TextField InputLabelProps={{ shrink: true }} label="休憩" variant="outlined" name="rest_time" type="time"  inputProps={{ step: 900}} onChange={e => handleInputChageConvertHHmmToMsec(e)} value={Shift_mst.rest_time===undefined? "" : commonFnc.getHHmmForTextField(Shift_mst.rest_time)}/>
                    <input hidden label="休憩" name="rest_time_msec" type="number" value={Shift_mst.rest_time} readOnly/>

                    <FormControl className="shiftmstFormControl">
                        <InputLabel className="InputLabel-style shiftmstInputLabel-style" id="shift_group_cd-label">シフト分類</InputLabel>
                        <Select
                        label="shift_group_cd-label"
                        variant="outlined"
                        name="shift_group_cd"
                        className="shiftmstFormSelect"
                        onChange={e => handleInputChage(e)}
                        value={Shift_mst.shift_group_cd}
                        defaultValue={Shift_mst.shift_group_cd}
                        >
                            {shiftGroupCdList.map((shiftGroupCd, index)=>(
                                <MenuItem value={shiftGroupCd}>
                                    <Tooltip title={shiftGroupInfo[shiftGroupCd]?.note}>
                                        <span class="tooltipspan">
                                            {shiftGroupInfo[shiftGroupCd]?.name}
                                        </span>
                                    </Tooltip>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <TextField label="公休消費時間" variant="outlined" name="public_holiday_time" type="number" onChange={e => handleInputChage(e)} value={Shift_mst.public_holiday_time===undefined? "" : Shift_mst.public_holiday_time}/>
                    <TextField label="有給消費時間" variant="outlined" name="paid_holiday_time" type="number" onChange={e => handleInputChage(e)} value={Shift_mst.paid_holiday_time===undefined? "" : Shift_mst.paid_holiday_time}/>
                </div>
                {/* <div>
                    <TextField label="部署CD" variant="outlined" name="department_cd" type="text" onChange={e => handleInputChage(e)} value={Shift_mst.department_cd===undefined? "" : Shift_mst.department_cd} readOnly/>
                    <TextField label="時間有給前後フラグ" variant="outlined" name="b_a_cd" type="text" onChange={e => handleInputChage(e)} value={Shift_mst.b_a_cd===undefined? "" : Shift_mst.b_a_cd} readOnly/>
                </div> */}
                <div>
                    <TextField label="使用開始日" InputLabelProps={{ shrink: true }} variant="outlined" name="use_day_from" type="date" onChange={e => handleInputChage(e)} value={commonFnc.formatDate(Shift_mst.use_day_from===undefined? "" : Shift_mst.use_day_from).split(' ')[0]}/>
                    <TextField label="使用終了日" InputLabelProps={{ shrink: true }} variant="outlined" name="use_day_to" type="date" onChange={e => handleInputChage(e)} value={commonFnc.formatDate(Shift_mst.use_day_to===undefined? "" : Shift_mst.use_day_to).split(' ')[0]}/>
                </div>
                <div>
                    <TextField label="並び" variant="outlined" name="seq" type="number" onChange={e => handleInputChage(e)} value={Shift_mst.seq===undefined? "" : Shift_mst.seq}/>
                    <TextField label="備考" variant="outlined" name="note" onChange={e => handleInputChage(e)} value={Shift_mst.note===undefined? "" : Shift_mst.note}/>
                </div>
                <div>
                    <FormControl className="shiftmstFormControl">
                        <InputLabel className="InputLabel-style shiftmstInputLabel-style" id="backgroundcolor-label">背景色</InputLabel>
                        <Select
                        labelId="backgroundcolor-label"
                        variant="outlined"
                        name="backgroundcolor"
                        className="shiftmstFormSelect"
                        onChange={e => handleInputChage(e)}
                        value={Shift_mst.backgroundcolor===undefined? "" : Shift_mst.backgroundcolor}
                        defaultValue={Shift_mst.backgroundcolor}
                        >
                            <MenuItem value={"transparent"}> <div class="colorsample_item" style={{backgroundColor:"transparent"}}></div> None</MenuItem>
                            <MenuItem value={"tomato"}>      <div class="colorsample_item" style={{backgroundColor:"tomato"}}>     </div>トマト</MenuItem>
                            <MenuItem value={"blue"}>        <div class="colorsample_item" style={{backgroundColor:"blue"}}>       </div>青</MenuItem>
                            <MenuItem value={"ForestGreen"}> <div class="colorsample_item" style={{backgroundColor:"ForestGreen"}}></div>フォレストグリーン</MenuItem>
                            <MenuItem value={"SlateBlue"}>   <div class="colorsample_item" style={{backgroundColor:"SlateBlue"}}>  </div>スレートブルー</MenuItem>
                            <MenuItem value={"LightGreen"}>  <div class="colorsample_item" style={{backgroundColor:"LightGreen"}}> </div>黄緑</MenuItem>
                            <MenuItem value={"Lime"}>        <div class="colorsample_item" style={{backgroundColor:"Lime"}}>       </div>ライム</MenuItem>
                            <MenuItem value={"Peru"}>        <div class="colorsample_item" style={{backgroundColor:"Peru"}}>       </div>ペルー</MenuItem>
                             
                             
                        </Select>
                        {/* <FormHelperText>勤務カレンダーでの背景色を指定できます。</FormHelperText> */}
                    </FormControl>
                    <FormControl className="shiftmstFormControl">
                        <InputLabel className="InputLabel-style shiftmstInputLabel-style" id="textcolor-label">文字色</InputLabel>
                        <Select
                        labelId="textcolor-label"
                        variant="outlined"
                        name="textcolor"
                        className="shiftmstFormSelect"
                        onChange={e => handleInputChage(e)}
                        value={Shift_mst.textcolor===undefined? "" : Shift_mst.textcolor}
                        defaultValue={Shift_mst.textcolor}
                        >
                            <MenuItem value={""}><div class="colorsample_item"></div>Default</MenuItem>
                            <MenuItem value={"snow"}><div class="colorsample_item" style={{backgroundColor:"snow"}}></div>雪</MenuItem>
                        </Select>
                        {/* <FormHelperText>勤務カレンダーでの文字色を指定できます。</FormHelperText> */}
                    </FormControl>
                </div>
                <div>
                    <FormControl className="shiftmstFormControl">
                        <InputLabel className="InputLabel-style shiftmstInputLabel-style" id="log_del_flg-label">有効/無効</InputLabel>
                        <Select
                        labelId="log_del_flg-label"
                        variant="outlined"
                        name="log_del_flg"
                        className="shiftmstFormSelect"
                        onChange={e => handleInputChage(e)}
                        value={Shift_mst.log_del_flg===undefined? false : Shift_mst.log_del_flg}
                        defaultValue={Shift_mst.log_del_flg}
                        >
                            <MenuItem value={false}>有効</MenuItem>
                            <MenuItem value={true}>無効</MenuItem>
                        </Select>
                        {/* <FormHelperText>勤務カレンダーでの文字色を指定できます。</FormHelperText> */}
                    </FormControl>
                    <TextField label="日付切替時刻" InputLabelProps={{ shrink: true }} variant="outlined" name="shift_switching_time" type="time" onChange={e => handleInputChage(e)} value={Shift_mst.shift_switching_time===undefined? "" : Shift_mst.shift_switching_time}/>
                </div>
                {/* <Button className="acction_button" variant="outlined" type="submit" style={{margin:"7px"}}>登録</Button> */}
                <Button className="acction_button" variant="outlined" onClick={handleSubmit} style={{margin:"7px"}}>登録</Button>
            </div>
            <div >
                <p style={{"paddingLeft":"10px"}}>シフトボタンサンプル：</p>
                <div className="shift_type" 
                     style={{backgroundColor: Shift_mst?.backgroundcolor? Shift_mst.backgroundcolor: "transparent" ,color: Shift_mst?.textcolor ? Shift_mst.textcolor : "black", margin:"7px", border: "solid 0.5px #D3D3D3"}}>
                    サンプル
                </div>
            </div>
        </div>
        // </form>
    );
}
export default RequestShiftMstForm;